import React from 'react';
import styled from 'styled-components';
import axios from '../../services/axios'; // Ajuste o caminho conforme necessário
import { toast } from 'react-toastify';
import { primaryColor } from '../../config/colors';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 500px;
  z-index: 1001;
`;

const CloseButton = styled.button`
  float: right;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  margin-right: 10px;
  margin-top: 5px;

  &:hover {
    color: #000;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }
`;

const ToggleStatusButton = styled.button`
  background-color: ${primaryColor}; 
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  &:hover {
    background-color: #006D99;
  }
`;

const UserModal = ({ user, onClose }) => {
  if (!user) return null;

  const toggleStatus = async () => {
    try {
      const newStatus = !user.active_account;
      await axios.put(`/user/${user.id}`, { active_account: newStatus });
      onClose(); // Optionally close the modal after update
      toast.success(`Status do usuário atualizado para ${newStatus ? 'Ativo' : 'Inativo'}: Recarregue a página para ver essa mudança.`);
    } catch (error) {
      console.error('Failed to update user status:', error);
      toast.error('Erro ao atualizar o status do usuário');
    }
  };

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <div>
          <h2>{user.name}</h2>
          <p>Email: {user.email}</p>
          <p>Telefone: {user.phone}</p>
          <p>CPF: {user.cpf}</p>
          <p>Máximo de Ativações: {user.max_activations}</p>
          <p>Status: {user.active_account ? 'Ativo' : 'Inativo'}</p>
          <ToggleStatusButton onClick={toggleStatus}>
            {user.active_account ? 'Desativar usuário' : 'Ativar'}
          </ToggleStatusButton>
        </div>
      </ModalContent>
    </ModalBackdrop>
  );
};

export default UserModal;
