import React, { useEffect, useState } from "react";
import { Container } from "../../styles/GlobalStyles";
import { ContainerSec } from "./styled";
import axios from '../../services/axios';
import EvolutionUsersTotal from "../../components/EvolutionUsersTotal";
import EvolutionModal from '../../components/EvolutionModal';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchEmail, setSearchEmail] = useState(''); // Estado para o email de busca
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let url = `/evolution?page=${currentPage}`;
        const response = await axios.get(url);
        if (response.data.users) {
          setUsers(prevUsers => (currentPage === 1 ? response.data.users : [...prevUsers, ...response.data.users]));
        } else {
          console.error('No data received');
        }
      } catch (error) {
        console.error('Erro ao buscar os dados dos usuários', error);
      }
    };

    fetchUsers();
  }, [currentPage]);

  const searchUserByEmail = async (email) => {
    try {
      const response = await axios.get(`/evolution/email/${email}`);
      if (response.data) {
        setUsers([response.data]); // Coloca o usuário encontrado na lista de usuários
        setCurrentPage(1); // Reseta a página
      } else {
        console.error('No user found');
      }
    } catch (error) {
      console.error('Erro ao buscar usuário por email', error);
    }
  };

  const openModal = (userData) => {
    setSelectedUser(userData);
    setIsModalOpen(true);
  };

  const handleLoadMore = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleSearchChange = (e) => {
    setSearchEmail(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchUserByEmail(searchEmail);
  };

  return (
    <ContainerSec>
      <EvolutionUsersTotal />

      <Container>
        <h1>Todos os usuários</h1>

        {/* Input de Busca por Email */}
        <form onSubmit={handleSearchSubmit}>
          <input
            type="text"
            placeholder="Buscar usuário por email..."
            value={searchEmail}
            onChange={handleSearchChange}
          />
          <button type="submit">Buscar</button>
        </form>

        <table className="user-table">
          <thead>
            <tr>
              <th>Cliente</th>
              <th>Ativações</th>
              <th>Telefone</th>
              <th>Email</th>
              <th>Máximo de ativações</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id} onClick={() => openModal(user)}>
                <td>{user.name}</td>
                <td>{user.activations}</td>
                <td>{user.phone}</td>
                <td>{user.email}</td>
                <td>{user.max_activations}</td>
                <td>{user.active_account ? 'Ativo' : 'Inativo'}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <button onClick={handleLoadMore}>Carregar mais</button> {/* Botão para carregar mais usuários */}

        {isModalOpen && <EvolutionModal user={selectedUser} onClose={() => setIsModalOpen(false)} />}
      </Container>
    </ContainerSec>
  );
}