import styled from "styled-components";
import { backgroundColor, primaryColor } from "../../config/colors";

export const Nav = styled.nav`
    background-color: #FFF;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        color: #9197B3;
        margin: 0 10px 0 0;
        font-weight: bold;
    }

    .navbar-container {
        display: flex;
        gap: 15px;
    }

    .link-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
    }

    .container-dash {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1000px;
        width: 80%;
        margin: 0 auto;
    }
`