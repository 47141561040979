import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import { isEmail, isInt } from 'validator';
import { Container } from "../../styles/GlobalStyles";
import { ContainerSec } from "./styled";
import * as exampleActions from '../../store/modules/example/actions';
import { get } from 'lodash';
import { toast } from "react-toastify";
import axios from "../../services/axios";

export default function Manage({ match }) {
    const id = get(match, 'params.id', 0);
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [cpf, setCpf] = useState('');
    const [maxActivations, setMaxActivations] = useState(1);
    const [activeAccount, setActiveAccount] = useState(true);

    useEffect(() => {
        if(!id) return;
    }, [id])

    const handleChange = (e) => {
        setActiveAccount(e.target.value === 'true');
    };

    async function handleSubmit(e) {
        e.preventDefault()

        let formErrors = false;

        if (name.length < 3 || name.length > 255) {
            toast.error('Nome precisa ter entre 3 e 255 caracteres');
            formErrors = true;
        }

        if (!isEmail(email)) {
            toast.error('Email inválido');
            formErrors = true;
        }

        if (!isInt(phone) || phone.length < 10 || phone.length > 11) {
            toast.error('Telefone inválido');
            formErrors = true;
        }

        if (!isInt(cpf) || cpf.length !== 11) {
            toast.error('CPF inválido');
            formErrors = true;
        }

        if (!isInt(String(maxActivations)) || parseInt(maxActivations, 10) <= 0) {
            toast.error('Máximo de ativações deve ser um número inteiro positivo');
            formErrors = true;
        }

        if (formErrors) {
            return;
        }

        await axios.post('/user', {
            name,
            email,
            max_activations: maxActivations,
            phone,
            cpf,
            activeAccount
        });
        toast.success('Usuário criado com com sucesso!')
    };

    return (
        <ContainerSec>
            <Container>
                <h1>{id ? 'Editar usuário' : 'Novo usuário'}</h1>
                <form onSubmit={handleSubmit}>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Nome do usuário" />
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email do usuário" />
                    <input type="text" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Telefone do usuário" />
                    <input type="text" value={cpf} onChange={e => setCpf(e.target.value)} placeholder="CPF do usuário" />
                    <input type="number" value={maxActivations} onChange={e => setMaxActivations(parseInt(e.target.value, 10))} placeholder="Máximo de ativações do usuário" />
                    <select value={String(activeAccount)} onChange={handleChange}>
                        <option value="true">Ativo</option>
                        <option value="false">Inativo</option>
                    </select>
                    <button type="submit">Criar usuário</button>
                </form>
            </Container>
        </ContainerSec>
    );
}

Manage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};
