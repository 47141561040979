import * as types from '../types';

const initialState = {
  isLoggedIn: false,
  token: false,
  manager: {},
  isLoading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      const newState = { ...state };

      newState.isLoggedIn = true;
      newState.token = action.payload.token;
      newState.manager = action.payload.manager;

      return newState;
    }

    case types.LOGIN_REQUEST: {
      console.log('PAYLOAD', action.payload)
      return state;
    }

    case types.LOGIN_FAILURE: {
      const newState = { ...initialState };
      return newState;
    }


    default: {
      return state;
    }
  }
}