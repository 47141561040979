import styled from "styled-components";
import { primaryColor } from "../../config/colors";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60%;

    div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: #FFF;
        padding: 20px;
        border-radius: 7px;
        min-width: 520px;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    form input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ddd;
    }
`