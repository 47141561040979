import React, { useState, useEffect } from 'react';
import axios from '../../services/axios';
import { MetricsContainer, Metric } from "./styled";

const UserMetrics = () => {
    const [metrics, setMetrics] = useState({
        total: 0,
        newThisMonth: 0,
        newLastMonth: 0,
        growth: 0
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchMetrics = async () => {
            try {
                const totalResponse = await axios.get('/user/metrics/total');
                const newThisMonthResponse = await axios.get('/user/metrics/new/this-month');
                const newLastMonthResponse = await axios.get('/user/metrics/new/last-month');
    
                const newThisMonth = newThisMonthResponse.data.newUsers || 0;
                const newLastMonth = newLastMonthResponse.data.newUsersLastMonth || 0;
    
                let growth;
                if (newLastMonth === 0) {
                    growth = (newThisMonth * 100).toFixed(2);
                } else {
                    growth = ((newThisMonth - newLastMonth) / newLastMonth * 100).toFixed(2);
                }
    
                setMetrics({
                    total: totalResponse.data.total || 0,
                    newThisMonth: newThisMonth,
                    newLastMonth: newLastMonth,
                    growth: growth
                });
            } catch (err) {
                setError('Não foi possível obter as métricas dos usuários.');
                console.error(err);
            }
        };
    
        fetchMetrics();
    }, []);

    const formatMetric = (metric) => {
        return metric === null || metric === undefined ? '0' : metric.toString();
    };

    return (
        <MetricsContainer>
  <Metric>
  <div className="icon">
      <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0302 18.4092C15.8552 18.3924 15.6452 18.3924 15.4527 18.4092C11.2877 18.2748 7.98022 14.9991 7.98022 10.9673C7.98022 6.85163 11.4452 3.50867 15.7502 3.50867C20.0377 3.50867 23.5202 6.85163 23.5202 10.9673C23.5027 14.9991 20.1952 18.2748 16.0302 18.4092Z" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.7171 6.86774C32.1121 6.86774 34.8421 9.50515 34.8421 12.7473C34.8421 15.9223 32.2171 18.5093 28.9446 18.6269C28.8046 18.6101 28.6471 18.6101 28.4896 18.6269" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7.2805 24.6073C3.0455 27.3287 3.0455 31.7636 7.2805 34.4682C12.093 37.5592 19.9855 37.5592 24.798 34.4682C29.033 31.7468 29.033 27.3119 24.798 24.6073C20.003 21.5331 12.1105 21.5331 7.2805 24.6073Z" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M32.0947 33.7466C33.3547 33.4946 34.5447 33.0074 35.5247 32.2851C38.2547 30.3196 38.2547 27.0774 35.5247 25.112C34.5622 24.4064 33.3897 23.936 32.1472 23.6673" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  </div>


    <div className='total-fetch'>
        <span>Total de utilizadores</span>
        <strong>{formatMetric(metrics.total)}</strong>
    </div>
  </Metric>
  <Metric>
  <div className="icon">
      <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0667 30.6265L26.6 33.0583L31.6667 28.1946" stroke="#00AC4F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M20.2669 17.5397C20.1003 17.5237 19.9003 17.5237 19.7169 17.5397C15.7503 17.4117 12.6003 14.2919 12.6003 10.4522C12.5836 6.53247 15.9003 3.34869 19.9836 3.34869C24.0669 3.34869 27.3836 6.53247 27.3836 10.4522C27.3836 14.2919 24.2169 17.4117 20.2669 17.5397Z" stroke="#00AC4F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.9832 35.042C16.9498 35.042 13.9332 34.306 11.6332 32.8341C7.59982 30.2423 7.59982 26.0186 11.6332 23.4428C16.2165 20.499 23.7332 20.499 28.3165 23.4428" stroke="#00AC4F" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  </div>


            <div className='total-fetch'>
                <span>Novos esse mês</span>
                <strong>{metrics.newThisMonth}</strong>
                {metrics.growth !== 'N/A' && (
                    <span className='growth-percentage'>
                        {metrics.growth > 0 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99988 17L9.99988 5" stroke="#00AC4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M4.1665 9.99999L9.99984 4.16666L15.8332 9.99999" stroke="#00AC4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
 : metrics.growth < 0 ? <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M10 3L10 15" stroke="#D0004B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 <path d="M15.8334 10L10 15.8333L4.16671 10" stroke="#D0004B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
 </svg> : ''} {metrics.growth}%
                    </span>
                )}
            </div>
  </Metric>
  <Metric>

  <div className="icon">
      <svg width="42" height="41" viewBox="0 0 42 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.27 3.5484H30.7125C36.9425 3.5484 38.5 5.0435 38.5 11.0071V21.6407C38.5 27.6211 36.9425 29.0994 30.73 29.0994H11.27C5.0575 29.1162 3.5 27.6211 3.5 21.6575V11.0071C3.5 5.0435 5.0575 3.5484 11.27 3.5484Z" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M21 29.1158V37.1456" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M3.5 22.0271H38.5" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.125 37.1461H28.875" stroke="#00AC4F" stroke-width="2.625" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  </div>

    <div className='total-fetch'>
        <span>Ativos hoje</span>
        <strong>{formatMetric('Breve')}</strong>
    </div>
  </Metric>
</MetricsContainer>
    );
};

export default UserMetrics;
