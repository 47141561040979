import styled from 'styled-components';

export const MetricsContainer = styled.div`
  background-color: #FFFFFF; /* Fundo branco como na imagem */
  width: 80%;
  margin: 0 auto;
  max-width: 820px;
  padding: 20px;
  border-radius: 10px; /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Espaço entre os itens */
  margin-bottom: 20px; /* Espaçamento abaixo do container */
`;

export const Metric = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .growth-percentage {
    color: ${props => (props.growth > 0 ? 'green' : props.growth < 0 ? 'red' : 'black')};
    font-size: 0.85em;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .growth-percentage svg {
    width: 15px;
    margin-right: 4px;
  }

  span {
    font-size: 14px;
    color: #9E9E9E; /* Cor do texto */
    margin-bottom: 5px;
  }

  strong {
    font-size: 22px;
    color: #333333; /* Cor do número */
    font-weight: bold;
  }

  .total-fetch {
    display: flex;
    flex-direction: column;
  }

  .icon {
    padding: 15px;
    background-color: #D3FFE7;
    border-radius: 50%;
  }

  svg {
    width: 30px;
    height: 25px;
  }
`;