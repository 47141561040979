import React from "react";
import { Switch } from "react-router-dom";
import MyRoute from "./MyRoute";

import Login from "../pages/Login";
import Manage from "../pages/Manage";
import Evolution from "../pages/Evolution";
import Users from "../pages/Users";
import EvolutionUsers from "../pages/EvolutionUsers";
import Page404 from "../pages/Page404";

export default function Routes() {
    return (
        <Switch>
            <MyRoute exact path='/login' component={Login} />
            <MyRoute exact path='/' component={Users} isClosed/>
            <MyRoute exact path='/evolution' component={EvolutionUsers} isClosed/>
            <MyRoute exact path='/manage' component={Manage} isClosed/>
            <MyRoute exact path='/managevolution' component={Evolution} isClosed/>
            <MyRoute path='*' component={Page404} />
        </Switch>
    );
}