import styled from "styled-components";
import { primaryColor } from "../../config/colors";

export const ContainerSec = styled.div`
    margin-top: 50px;
    text-align: center;

    form {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 8px;

        input {
            width: 80%;
            padding: 10px;
        }

        button {
            width: 80%;
            margin-top: 20px;
        }
    }
`