import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Container } from "./styled";
import * as actions from '../../store/modules/auth/actions'
import { toast } from "react-toastify";
import { get } from 'lodash'

import { isEmail } from 'validator'

export default function Login(props) {
    const dispatch = useDispatch();

    const prevPath = get(props, 'location.state.prevPath', '/')

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function handleSubmit(e) {
        e.preventDefault()
        let formErrors = false;

        if (!isEmail(email)) {
            formErrors = true;
            toast.error('E-mail inválido!')
        }

        if (password.length < 10 || password.length > 50) {
            formErrors = true;
            toast.error('Senha inválida')
        }

        if (formErrors) return;

        dispatch(actions.loginRequest({ email, password }))
    }

    return (
        <Container>
            <div>
            <h1>Login</h1>

            <form onSubmit={handleSubmit}>
                <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="Seu e-mail"/> 

                <input type="text" value={password} onChange={e => setPassword(e.target.value)} placeholder="Sua senha"/> 

                <button type="submit">Entrar</button>
            </form>
            </div>
        </ Container>
    )
}