export const primaryColor = '#5932EA';
export const secondaryColor = '#00AC4F';
export const primaryDarkColor = '#1A1A1D';

export const primaryTextColor = '#000000';
export const secondaryTextColor = '#ACACAC'

export const backgroundColor = '#FAFBFF';

export const sucessColor = '#00B087';
export const infoColor = '#0197F6';
export const errorColor = '#D0004B';
export const warningColor = '#F2AF29';