import styled from "styled-components";
import { primaryColor } from "../../config/colors";

export const ContainerSec = styled.div`
    padding-top: 40px;


    /* Adicione este CSS no seu arquivo de estilos globais ou correspondente */
.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.user-table th,
.user-table td {
  text-align: left;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}

.user-table th {
  background-color: #f2f2f2;
}

.user-table tr:last-child td {
  border-bottom: none;
}

/* Estilos para tela pequena/mobile */
@media (max-width: 768px) {
  .user-table th:not(:nth-child(2)), /* Esconde colunas, mantendo Nome e Email */
  .user-table td:not(:nth-child(2)) {
    display: none;
  }
}

`

export const Title = styled.h1`
    color: ${primaryColor}
`

